
import swal from "sweetalert2";
import axios from "axios";

export default {
	data() {
		return {
			modules: [],
			/// lecturer attendance
			tabSelectedAttendanceModule: null,
			createAttendanceIndex: 0,
			selectedAttendanceModule: null,
			selectedAttendanceModuleStudents: [],
			selectedAttendanceDuration: "5 minutes",
			selectedAttendanceDate: null,
			attendanceRegister: null,
			attendanceRegisterHistoryTimes: [],
			attendanceRegisterHistoryStudents: [],
			attendanceRegisterHistoryStudentsLoading: false,
			attendanceError: "",
			isLoading: false,
		};
	},
	mounted() {
		this.getAllMyModules();
	},
	methods: {
		async getAllMyModules() {
			try {
				if (!this.$store.state.currentUser) {
					return this.$dialog.fire(
						"Unable to get modules",
						"You have to be logged in to perform this action",
						"error",
					);
				}
				if (
					!this.$store.state.currentSchool ||
					this.$store.state.currentSchool.role != "LECTURER"
				) {
					return this.$dialog.fire(
						"Unable to get modules",
						"You have to be a lecturer to perform this action",
						"error",
					);
				}
				this.modules = await this.$api.getAllMyModules({
					schoolUrl: this.$store.state.currentSchool.url,
					filter: "name code",
				});
				console.log(this.modules);
			} catch (err) {
				this.$dialog.fire("Unable to get modules", err.message, "error");
				this.txtError = err.message;
			}
		},
		async createAttendance() {
			this.attendanceError = "";
			if (!this.selectedAttendanceModule) {
				this.attendanceError = "Please select a module";
				return;
			}
			let duration = 310;
			switch (this.selectedAttendanceDuration) {
				case "Never":
					duration = 31538000;
					break;
				case "5 minutes":
					duration = 310;
					break;
				case "10 minutes":
					duration = 610;
					break;
				case "15 minutes":
					duration = 910;
					break;
				case "30 minutes":
					duration = 1810;
					break;
			}

			try {
				this.isLoading = true;

				const results = await this.$api.createAttendance({
					moduleId: this.selectedAttendanceModule,
					durationInSeconds: duration,
				});

				console.log({ results });

				if (results.error) {
					this.attendanceError = results.error;
					return;
				}

				this.createAttendanceIndex = 1;
				this.attendanceRegister = results;
			} catch (err) {
				swal.fire(
					"Unable to create attendance register",
					(err as Error).message,
					"error",
				);
				this.attendanceError = (err as Error).message;
			} finally {
				this.isLoading = false;
			}
		},
		async changeAttendanceTab(e) {
			if (e !== 1) {
				return;
			}
			// Case is tab-home-history
			this.isLoading = true;
			this.attendanceError = "";

			try {
				const results = await this.$api.getAttendanceForModule({
					moduleId: this.selectedAttendanceModule,
				});

				console.log({ results });
				if (results.error) {
					throw new Error(results.error);
				}

				this.attendanceRegisterHistoryTimes = results;
			} catch (err) {
				this.attendanceError = (err as Error).message;
				swal.fire(
					"Unable to get the attendance list",
					(err as Error).message,
					"error",
				);
			} finally {
				this.isLoading = false;
			}
		},
		async setStudentsForModule(moduleId) {
			this.selectedAttendanceModuleStudents = [];
			if (!moduleId) return;
			const students = await this.$api.getAllStudentsWithModules();
			console.log({ students });
			// axios
			// 	.get(
			// 		this.$store.state.settings.baseLink +
			// 			"/s/students/all/for/module/" +
			// 			moduleId,
			// 	)
			// 	.then((results) => {
			// 		this.isLoading = false;
			// 		this.selectedAttendanceModuleStudents = results.data.map((v) => {
			// 			return {
			// 				_id: v._id,
			// 				username: v.username,
			// 				lastname: v.lastname,
			// 				firstname: v.firstname,
			// 				selected: true,
			// 			};
			// 		});
			// 		console.log("results", this.selectedAttendanceModuleStudents);
			// 	})
			// 	.catch((err) => {
			// 		this.isLoading = false;
			// 		if (err.response != null && err.response.status == 512) {
			// 			swal.fire(err.response.data, "", "error");
			// 		} else {
			// 			console.log(err);
			// 			swal.fire(
			// 				"Unable to get the attendance list",
			// 				"Try again later",
			// 				"error",
			// 			);
			// 		}
			// 	});
		},
		async selectAttendanceDate(time) {
			this.selectedAttendanceDate = time;
			if (!this.selectedAttendanceDate?._id) {
				return;
			}
			try {
				this.attendanceRegisterHistoryStudentsLoading = true;
				const attendance = await this.$api.getAttendanceById(
					this.selectedAttendanceDate._id,
				);

				if (attendance.error) {
					swal.fire(
						"Unable to get the attendance list",
						attendance.error,
						"error",
					);
					return;
				}

				this.attendanceRegisterHistoryStudents = attendance.students;
			} catch (err) {
				swal.fire(
					"Unable to get the attendance list",
					(err as Error).message,
					"error",
				);
			} finally {
				this.attendanceRegisterHistoryStudentsLoading = false;
			}
		},
		submitBulkAttendance() {
			console.log("Here we go!");
			const students = this.selectedAttendanceModuleStudents
				? this.selectedAttendanceModuleStudents
						.filter((v) => v && v.selected)
						.map((v) => v._id)
				: null;
			const code = this.attendanceRegister
				? this.attendanceRegister.code
				: null;
			if (!students || !code) {
				swal.fire(
					"Unable to sign register",
					"make sure you selected students",
					"error",
				);
				return;
			}
			console.log("selected students", students);
			console.log("selected code", code);
			this.isLoading = true;
			axios
				.post(
					this.$store.state.settings.baseLink +
						"/attendance/sign/bulk/students",
					{
						students,
						code,
					},
				)
				.then((results) => {
					this.isLoading = false;
					swal.fire(results.data, "", "success");
				})
				.catch((err) => {
					this.isLoading = false;
					if (err.response != null && err.response.status == 512) {
						swal.fire(err.response.data, "", "error");
					} else {
						console.log(err);
						swal.fire(
							"Unable to get the attendance list",
							"Try again later",
							"error",
						);
					}
				});
		},
	},
};
