<template>
  <div>
    <v-row>
      <v-col cols="10"> </v-col>
      <v-col cols="2">
        <v-btn right v-on:click="$router.back()" class="primary justify-end">
          <v-icon>mdi-keyboard-backspace</v-icon>
          <span class="px-2">Back</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xlg="6" class="mx-auto">
        <v-card class="pa-3">
          <v-card-header class="text-center">
            <div class="display-2 py-2">
              Report a student
            </div>
            <p class="title secondary--text">{{ Report.student ? `${Report.student.lastname} ${Report.student.firstname}` : "Notification will be sent to the student's parent/s" }}</p>
          </v-card-header>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="currentPage == -1">
      <v-col cols="12" sm="12" md="8" class="mx-auto">
        <v-row>
          <v-col cols="8" class="mx-auto py-0">
            <v-text-field :loading="isLoading" v-model="txtSearch" label="Search for student..." outlined></v-text-field>
          </v-col>
          <v-col cols="8" class="mx-auto py-0">
            <v-card>
              <v-subheader>Pick the student</v-subheader>
              <v-card-text style="height:30vh;overflow-y:auto">
                <v-list :loading="isLoading" dense>
                  <v-list-item-group v-model="Report.student" color="primary">
                    <v-list-item
                      :value="student"
                      v-for="(student, i) in students.filter(
                        (v) =>
                          v &&
                          (JSON.stringify(v)
                            .toLowerCase()
                            .indexOf(txtSearch.toLowerCase()) >= 0 ||
                            txtSearch.length == 0)
                      )"
                      :key="i"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-account-circle-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ student.lastname }} {{ student.firstname }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" class="mx-auto">
            <v-btn :loading="isLoading" block @click="GoToNextPage(false)" color="primary" outlined>Next <v-icon>mdi-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="currentPage == 0">
      <v-col cols="12" sm="12" md="8" class="mx-auto">
        <v-row class="row">
          <v-col cols="8" class="mx-auto py-0">
            <v-btn icon color="error" @click="currentPage = -1">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="Report.student" cols="8" class="mx-auto py-0">
            <v-card :loading="isLoading">
              <v-card-text>
                <p class="subtitle-2 mb-2">Which subject was this? <span class="report-imp">*</span></p>
                <v-chip-group v-model="Report.module" column>
                  <v-chip v-for="(_module, i) in modules" :value="_module" :key="i" filter outlined>{{ _module.name }} ({{ _module.code }})</v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="8" class="mx-auto my-2 py-0">
            <v-card class="pa-2">
              <v-textarea outlined label="What happened?" v-model="Report.Message" max="1000" counter></v-textarea>
            </v-card>
          </v-col>
          <v-col cols="8" class="mx-auto py-0">
            <v-card>
              <v-card-text>
                <p class="subtitle-2 mb-2">Actions to be performed <span class="report-imp">*</span></p>
                 <v-row>
                  <v-col cols="12" v-if="Report.student.parentsName && Report.student.parentsNumbers">
                    <p>
                      A system notification will be sent to {{ Report.student.parentsName }} (student's parent/s)
                    </p>  
                    <p>
                      An SMS will be sent to {{ Report.student.parentsName }} ({{ Report.student.parentsNumbers }})
                    </p>  
                  </v-col>
                  <v-col cols="12" v-if="!Report.student.parentsName">
                    <p class="red-text">
                      Parent's contact details are not available, please update the student's details to include the parent's contact details
                    </p>
                  </v-col>
                 </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" class="mx-auto">
            <v-btn :loading="isLoading" block @click="GoToNextPage(false)" color="primary" outlined>Next <v-icon>mdi-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="currentPage == 1">
      <v-col cols="12" sm="12" md="10" class="mx-auto">
        <v-row>
          <v-col cols="8" class="mx-auto py-0">
            <v-btn icon color="error" @click="currentPage = 0">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="Report.student && Report.module" cols="8" class="mx-auto">
            <div ref="emailCard" class="Email-cards">
              <div class="Email-header" style="font-size:20px;font-family:sans-serif;letter-spacing:1px; box-sizing:border-box; margin-top:60px;">
                <img class="corportal" align="left" style="width:160px;height:auto;margin-top:-40px;" src="https://coportal.net/img/icon.b3efbd4d.png" />
                <span style="">Coportal Communication</span>
              </div>
              <div class="Email-message-header" style="margin-left:15px;margin-top:90px;">
                <h5 style="font-weight:50;margin-bottom:20px">Dear Parent/Guardian</h5>
                <div>
                  Please read the following report concerning <strong style="font-weight:bold;">{{ Report.student.lastname }} {{ Report.student.firstname }}</strong> for <strong style="font-weight:bold;">{{ Report.module.name }}</strong> ({{ Report.module.code }})
                </div>
                <div style="margin-top:10px;margin-bottom:10px;">
                  The report is as follows :
                </div>
                <strong style="font-size:22px;font-weight:bold;">
                  {{ Report.Message }}
                </strong>
                <div style="margin-top:20px;margin-bottom:30px">
                  This report was sent to you by <strong style="font-weight:bold;"> {{ Report.Teacher }}</strong> (Teacher)
                </div>
                Best Regards,
                <br /><span>Coportal Communication</span> <br /><img class="corportal" align="left" style="width:160px;height:auto;opacity:0.1" src="https://coportal.net/static/img/coPortalLogo.jpg" />
              </div>
            </div>
          </v-col>
          <v-col cols="6" class="mx-auto">
            <v-btn :loading="isLoading" block @click="GoToNextPage(true)" color="primary" outlined>Send <v-icon>mdi-send</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="10" class="mx-auto">
        <p class="text-center error--text" v-show="txtError.length > 2">{{ txtError }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import swal from "sweetalert2";
const axios = require("axios");

export default {
	name: "Test",
	watch: {
		surveyAnswers(newVal, oldVal) {
			this.$forceUpdate();
		},
	},
	data() {
		return {
			txtError: "",
			txtSearch: "",
			Questionare: [],
			currentPage: -1,
			Subjects: [],
			Report: {
				student: null,
				Teacher: this.$store.state.user.username,
				TeacherID: this.$store.state.user.id,
				module: null,
				Message: "",
			},
			students: [],
			modules: [],
			isLoading: false,
		};
	},
	mounted() {
		this.isLoading = true;
		this.getAllDBStudents();
	},
	methods: {
		async getAllDBStudents() {
			try {
				this.isLoading = true;
				this.students = await this.$api.lookupIds(
					"students",
					this.$store.state.currentSchool.students,
					"",
				);
				this.isLoading = false;
			} catch (err) {
				swal.fire("Unable to load student", err.message, "error");
				this.isLoading = false;
			}
		},
		async GoToNextPage(final) {
			if (this.currentPage === -1) {
				if (!this.Report.student || !this.Report.student._id) {
					this.txtError = "Please select a student";
				} else {
					console.log("this.Report.student", this.Report.student);
					this.modules = await this.$api.getAllMyModulesForUser({
						userId: this.Report.student._id,
						schoolUrl: this.$store.state.currentSchool.url,
						filter: "",
					});
					console.log("this.modules", this.modules);
					this.currentPage++;
					this.txtError = "";
				}
			} else if (this.currentPage === 0) {
				if (!this.Report.module) {
					this.txtError = "Please select a Subject to report";
				} else if (this.Report.Message.length < 3) {
					this.txtError = "Please type in a Report";
				} else {
					this.currentPage++;
					this.txtError = "";
				}
			} else if (this.currentPage === 1 && final) {
				this.txtError = "";
				const htmlTemplate = new XMLSerializer().serializeToString(
					this.$refs.emailCard,
				);

				const results = await this.$api.reportStudent({
					moduleId: this.Report.module._id,
					studentId: this.Report.student._id,
					message: this.Report.Message,
					html: htmlTemplate,
				});

				if (results.error) {
					this.txtError = results.error;

					return;
				}

				swal.fire("Report successfully sent", results.data.message, "success");
				this.$router.push("/");
			}
		},
	},
};
</script>

<style scoped>
.select-container {
  text-align: center;
  max-width: 400px;
  min-width: 100%;
  margin: auto;
  margin-bottom: 30px;
}

.custom-selected {
  border: none;
  max-width: 400px;
  min-width: 100%;
  height: 60px;
  box-shadow: 0px 0px 10px 2px lightgrey;
  text-align-last: center;
}

input[type="radio"] {
  display: none;
}

label {
  padding: 10px 20px;
  box-shadow: 0px 0px 5px 1px lightgrey;
  margin: 5px;
  display: inline-block;
  cursor: pointer;
  color: black;
  min-width: 210px;
  text-align: center;
  font-family: "open sans";
}

input[type="radio"]:checked + label {
  background-color: rgb(36, 36, 36);
  cursor: default;
  color: ghostwhite;
}

.Report-que-header {
  width: 100%;
  margin-top: 10px;
  min-height: 30px;
  box-sizing: border-box;
  padding-left: 8px;
  padding-top: 3px;
  padding-bottom: 5px;
  color: black;
  font-size: 17px;
}

.report-imp {
  color: red;
  font-size: 11px;
}

.Report-que-body {
  width: 100%;
  min-height: 30px;
  margin-top: 10px;
  box-sizing: border-box;
  padding-left: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Email-cards {
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 50px;
  height: auto;
  width: 90%;
  margin: auto;
  box-shadow: 0px 0px 12px 2px lightgrey;
  padding-bottom: 20px;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.select-wrapper input.select-dropdown {
  display: none;
  z-index: 1;
}
</style>
