
import swal from "sweetalert2";
import Vue from "vue";
import AddStudent from "./Student/AddStudent";

export default Vue.extend({
	name: "Login",
	data() {
		return {
			showPassword: false,
			username: "",
			password: "",
			txtError: "",
			isLoading: false,
			pastUsers: [],
			showRegisterDialog: false,
			currentSchoolUrl:
				this.$store.state.currentSchool && this.$store.state.currentSchool.url,
		};
	},
	components: {
		AddStudent,
	},
	watch: {
		currentSchoolUrl(newVal, oldVal) {
			if (
				!this.$store.state.currentSchool ||
				newVal != this.$store.state.currentSchool.url
			) {
				this.$store.commit("setCurrentSchoolUrl", newVal);
			}
		},
	},
	mounted() {
		this.pastUsers = this.$session.get("users");
		this.getSchoolUrlFromUrl();
	},
	methods: {
		getSchoolUrlFromUrl() {
			let [schoolUrl] = document.location.host.split(".");
			if (schoolUrl && schoolUrl.indexOf("localhost") >= 0) {
				schoolUrl = "test";
			}
			this.currentSchoolUrl = schoolUrl;
		},
		AddedNewStudent(isAdded) {
			if (isAdded) {
				swal.fire("👍🏽", "Account successfully created", "success");
				this.showRegisterDialog = false;
			} else {
				swal.fire(
					"👍Unable to create your account",
					"Try again later",
					"error",
				);
			}
		},

		async SubmitLogin() {
			this.isLoading = true;
			this.txtError = "";
			if (this.password.length == 0)
				this.txtError = "Please enter a valid password";
			if (this.username.length == 0)
				this.txtError = "Please enter a valid user name";
			if (this.currentSchoolUrl.length == 0)
				this.txtError = "Please enter a valid school";
			if (this.txtError.length != 0) {
				this.isLoading = false;
				return;
			}

			try {
				const { user, token, school } = await this.$api.login(
					this.username,
					this.password,
					this.currentSchoolUrl,
				);
				await this.$api.authorize({
					token,
					user,
					school,
				});

				this.$api.createNewUsageLog({
					schoolId: this.$store.state.currentSchool?._id,
					platform: "WEB",
					event: "LOGIN",
					description: `${user?.firstname} ${user?.lastname} logged in using ${user?.username} to ${school?.name}`,
					metadata: {
						userId: user?._id,
						schoolId: school?._id,
					},
				});

				this.$router.push(`/?schoolUrl=${school.url}`);
				this.isLoading = false;
			} catch (err) {
				swal.fire("Unable to log you in", err.message, "error");
				this.txtError = err.message;
				this.isLoading = false;
			}
		},
		LoginAsUser(user) {
			if (user.type === "PARENT") {
				user.type = "STUDENT";
			}
			this.$store.commit("login", user);
			this.$router.push("/built4biz/home");
		},
	},
});
