
import swal from "sweetalert2";
import { samples } from "../plugins/schoolComponents";
import SchoolCollectionDialog from "./School/SchoolCollectionDialog.vue";
import Announcements from "./Announcement/Announcements.vue";

const axios = require("axios");
export default {
	name: "Home",
	components: {
		SchoolCollectionDialog,
		Announcements,
	},
	props: {
		schoolUrl: {
			type: String,
		},
	},
	data() {
		return {
			/// student attendance
			signAttendanceRegister: false,
			currentAttendanceCodeError: "",
			currentAttendanceCode: "",
			///
			isParent: false,
			partners: [
				{
					link: "https://www.onlinecareerguidance.co.za",
					img: "https://www.onlinecareerguidance.co.za/images/ocgbanner.jpg",
					name: "Online career guidance",
					description: "Online career guidance",
				},
				{
					link: "http://www.zabursaries.co.za",
					img: "http://www.zabursaries.co.za/wp-content/uploads/2016/09/bursaries2017-south-africa-logo.png",
					name: "ZA Bursaries",
					description: "ZA Bursaries",
				},
				{
					link: "http://ltprivatetutoring.co.za/",
					img: "http://ltprivatetutoring.co.za/img/logo2.png",
					name: "LT Private Tutoring",
					description: "LT Private Tutoring",
				},
			],
			announcements: [],
			showEmoji: false,
			isLoading: false,
			isChangingStudent: false,
			parentRelationship: "Mother",
			students: [],
			currentStudent: "",
			txtSearch: "",
			titleText: [
				"Welcome to Co-Portal.",
				"Please login",
				"Contact admin for your login info",
			],
			options: [
				{
					text: "School Management",
					description: "Administrate schools",
					icon: "mdi-table-cog",
					link: "/school/list",
					auth: ["ADMIN"],
				},
				{
					text: "Attendance Register",
					description: "Create and view registers",
					icon: "mdi-calendar-check",
					link: "/attendance/create",
					auth: ["LECTURER"],
				},
				{
					text: "Send Announcement",
					description: "Inform everyone in real time",
					icon: "mdi-bell",
					link: "/announcement/add",
					auth: ["LECTURER", "ADMIN"],
				},
				{
					text: "Sign Attendance Register",
					description: "Mark that you are in class",
					icon: "mdi-calendar-check",
					link: "/module/list/attend",
					signAttendanceRegister: true,
					auth: ["STUDENT"],
				},
				{
					text: "My Network",
					description: "Educational social network",
					icon: "mdi-account-network",
					link: "/my-network",
					auth: ["STUDENT", "LECTURER", "ADMIN"],
				},
				{
					text: "Surveys",
					description: "Rate the level of the lecturer",
					icon: "mdi-forum",
					link: "/survey/list",
					auth: ["STUDENT", "LECTURER", "ADMIN"],
				},
				{
					text: "Students",
					description: "List of registered students",
					icon: "mdi-account-supervisor",
					link: "/student/list",
					collection: "students",
					auth: ["LECTURER", "ADMIN"],
				},
				{
					text: "Edit profile",
					description: "Student's profile in detail",
					icon: "mdi-account-circle",
					link: "/student/update",
					auth: ["STUDENT"],
				},
				{
					text: "Lecturers",
					description: "List of registered lecturers",
					icon: "mdi-human-male-board",
					collection: "lecturers",
					link: "/lecturer/list",
					auth: ["ADMIN"],
				},
				{
					text: "Modules",
					description: "List of modules",
					icon: "mdi-book-open-page-variant",
					link: "/module/list",
					auth: ["ADMIN", "LECTURER", "STUDENT"],
				},
				{
					text: "Marks",
					description: "All results from past tests",
					icon: "mdi-check-all",
					link: "/marks/all",
					auth: ["STUDENT"],
				},
				{
					text: "Assessment results",
					description: "All results from past assessments",
					icon: "mdi-check-all",
					link: "/marks/sheet",
					auth: ["LECTURER", "ADMIN"],
				},
				{
					text: "Report a student",
					description: "Send a note to a student's parent",
					icon: "mdi-bullhorn-outline",
					link: "/Student/Report",
					auth: ["LECTURER", "ADMIN"],
				},
			],
			// new stuff as of v3
			editContent: null,
			headers: samples.headers,
			editContentSample: samples.editContentSample,
			editContentHeaders: samples.editContentHeaders,
		};
	},
	mounted() {
		this.reload();
	},
	methods: {
		async reload() {
			console.log(`School => ${this.schoolUrl}`);
		},
		async submitAttendanceCode() {
			this.isLoading = true;
			try {
				this.currentAttendanceCodeError = "";
				if (!this.currentAttendanceCode) {
					this.currentAttendanceCodeError = "Please enter a valid code";
					return;
				}

				const results = await this.$api.submitAttendanceCode({
					code: this.currentAttendanceCode,
				});

				if (results.error) {
					this.currentAttendanceCodeError = results.error;
					return;
				}

				swal.fire(results, "", "success");
				this.signAttendanceRegister = false;
			} catch (err) {
				this.currentAttendanceCodeError = (err as Error).message;
			} finally {
				this.isLoading = false;
			}
		},
		changeStudent() {
			const currentStudent = this.students.filter(
				(student) => student._id === this.currentStudent,
			);
			if (currentStudent[0]) {
				const user = currentStudent[0];
				this.$store.commit("login", {
					id: user._id,
					username: user.username,
					password: user.password,
					type: "STUDENT",
					isLoggedIn: true,
				});
				const currentParent = JSON.parse(
					JSON.stringify(this.$store.state.user.parent),
				);
				const newParent = currentStudent[0].parents.filter(
					(parent) => parent.email === currentParent.email,
				);

				if (newParent && newParent.length > 0) {
					currentParent.relationship = newParent[0].relationship;
				}
				this.$store.commit("setStudentParent", currentParent);
				this.isChangingStudent = false;
				this.currentStudent = "";
				swal.fire(
					"Success",
					`Successfully changed student to ${user.username}`,
					"success",
				);
			}
		},
		closedDialog() {
			this.editContent = null;
			this.reload();
		},
		async goToRoute(option) {
			if (option.collection) {
				const type = option.collection;
				const school = this.$store.state.currentSchool;
				if (!school) {
					return this.$dialog.fire(
						"A school is required",
						"Try logging in",
						"error",
					);
				}
				this.isLoading = true;
				console.log(this.$store.state.currentSchool);
				try {
					this.editContent = null;

					if (!school[type]) {
						school[type] = [];
					}
					this.editContent = {
						title: type,
						items: await this.$api.lookupIds(
							type,
							school[type],
							"-file.base64StringFile",
						),
						headers: this.editContentHeaders[type],
						school: school,
						sample: this.editContentSample[type],
					};
				} catch (err) {
					this.$dialog.fire("Unable to load " + type, err.message, "error");
				}
				this.isLoading = false;
			} else if (option.showStudents) {
				this.isChangingStudent = true;
			} else if (option.signAttendanceRegister) {
				this.signAttendanceRegister = true;
			} else {
				this.$router.push(option.link);
			}
		},
		capitalize(name) {
			if (!name) {
				return "";
			}
			return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
		},
		GotoExternal(url) {
			window.open(url, "_blank");
		},
	},
};
