
import Vue from "vue";

export default Vue.extend({
	data() {
		return {
			isLoading: false,
			announcements: [],
		};
	},
	mounted() {
		this.refresh();
	},
	methods: {
		async refresh() {
			this.getAllAnnouncements();
		},
		async getAllAnnouncements() {
			this.isLoading = true;
			try {
				//const announcements = await this.$api.getAllAnnouncements();
			} catch (err) {
				this.$dialog.fire("Unable to load announcements", err.message, "error");
				this.isLoading = false;
			}
		},
	},
});
